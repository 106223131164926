import request from '@/utils/request'

const baseUrl = '/manager/wxUser'

/**
 * 获取用户列表数据
 */
export const getWxUserList = data => {
  return request({
    url: baseUrl + '/pageVO',
    params: data
  })
}

/**
 * 更新
 */
export const updateWxUser = data => {
  return request({
    url: `${baseUrl}/update`,
    method: 'PUT',
    data
  })
}

/**
 * 删除指定数据
 */
export const deleteWxUser = ids => {
  return request({
    url: `${baseUrl}/${ids}`,
    method: 'DELETE'
    // data: ids
  })
}

/**
 * 获取用户详情
 */
export const wxUserDetail = id => {
  return request({
    url: `${baseUrl}/${id}`
  })
}
