<template>
  <div class="user-info-container">
    <el-card>
      <div id="userInfoBox" class="user-info-box">
        <!-- 标题 -->
        <h2 class="title">微信用户信息</h2>

        <div class="header">
          <!-- 头部渲染表格 -->
          <el-descriptions :column="2" border>
            <el-descriptions-item label="用户名">
              {{ detailData.username }}
            </el-descriptions-item>
            <el-descriptions-item label="手机号">
              {{ detailData.phoneNumber }}
            </el-descriptions-item>
            <el-descriptions-item label="用户角色">
              {{
                detailData.role
                  ? store.state.user.role[detailData.role].label
                  : '未知'
              }}
            </el-descriptions-item>
            <el-descriptions-item label="余额">
              {{
                detailData.balance && $filters.filterBalance(detailData.balance)
              }}
            </el-descriptions-item>
            <el-descriptions-item label="openid">
              {{ detailData.openid }}
            </el-descriptions-item>
            <el-descriptions-item label="上级的openid">
              {{ detailData.openids }}
            </el-descriptions-item>
            <el-descriptions-item label="注册时间">
              {{
                detailData.createTime &&
                  $filters.dateFilter(detailData.createTime)
              }}
            </el-descriptions-item>
          </el-descriptions>
          <!-- 头像渲染 -->
          <el-image
            class="avatar"
            :src="detailData.userAccountImg"
            :preview-src-list="[detailData.userAccountImg]"
          ></el-image>
        </div>
        <div class="body">
          <!-- 内容渲染表格 -->
          <!-- <el-descriptions direction="vertical" :column="1" border>
            <el-descriptions-item label="经历">
              <ul>
                <li v-for="(item, index) in detailData.experience" :key="index">
                  <span>
                    {{ $filters.dateFilter(item.startTime, 'YYYY/MM') }}
                    ----
                    {{ $filters.dateFilter(item.endTime, 'YYYY/MM') }}</span
                  >
                  <span>{{ item.title }}</span>
                  <span>{{ item.desc }}</span>
                </li>
              </ul>
            </el-descriptions-item>
            <el-descriptions-item label="专业">
              {{ detailData.major }}
            </el-descriptions-item>
            <el-descriptions-item label="荣耀">
              {{ detailData.glory }}
            </el-descriptions-item>
          </el-descriptions> -->
        </div>
        <!-- 尾部签名 -->
        <!-- <div class="foot">签字：___________日期:___________</div> -->
      </div>
    </el-card>
  </div>
</template>

<script setup>
import { wxUserDetail } from '@/api/wxuser'
import { defineProps, ref } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

const props = defineProps({
  id: {
    type: String,
    required: true
  }
})

// 数据相关
const detailData = ref({})
const getUserDetail = async () => {
  detailData.value = await wxUserDetail(props.id)
}
getUserDetail()
</script>

<style lang="scss" scoped>
.user-info-box {
  width: 1024px;
  margin: 0 auto;
  .title {
    text-align: center;
    margin-bottom: 18px;
  }
  .header {
    display: flex;
    ::v-deep .el-descriptions {
      flex-grow: 1;
    }
    .avatar {
      width: 187px;
      box-sizing: border-box;
      padding: 30px 20px;
      border: 1px solid #ebeef5;
      border-left: none;
    }
    .remark {
      margin-right: 12px;
    }
  }
  .body {
    ul {
      list-style: none;
      li {
        span {
          margin-right: 62px;
        }
      }
    }
  }
  .foot {
    margin-top: 42px;
    text-align: right;
  }
}
</style>
